import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectYear(props) {
  const { years, selectedYear, setSelectedYear } = props;
  const theme = useTheme();

  useEffect(() => {
    if (!selectedYear && years.length > 0) {
      setSelectedYear(years[years.length - 1]);
    }
  }, [years, selectedYear, setSelectedYear]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedYear(e.target.value);
  };

  return (
    <Select
      displayEmpty
      disableUnderline
      id='year-select'
      value={selectedYear || ''}
      variant='standard'
      onChange={handleSelect}
      sx={{ minWidth: '4rem' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{
        MenuListProps: { disablePadding: true },
        slotProps: {
          paper: { sx: { minWidth: 'unset !important' } },
        },
      }}>
      {years.map((year) => {
        return (
          <MenuItem key={year} value={year}>
            <ListItemText
              primary={year}
              slotProps={{ primary: { align: 'center' } }}
            />
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectYear.propTypes = {
  selectedYear: PropTypes.string,
  setSelectedYear: PropTypes.func,
};

export default SelectYear;
