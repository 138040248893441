import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import head from 'lodash/head';

const useTab = (tabs) => {
  const [queryParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(head(tabs));

  useEffect(() => {
    const tab = queryParams.get('tab');
    if (tabs.includes(tab)) {
      setSelectedTab(tab);
    } else {
      const defaultTab = head(tabs);
      setSelectedTab(defaultTab);
    }
  }, [tabs, queryParams]);

  return selectedTab;
};

export default useTab;
