import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowToLeft as faArrowToLeftLight,
  faBars as faBarsLight,
  faBellOn as faBellOnLight,
  faBolt as faBoltLight,
  faBroadcastTower as faBroadcastTowerLight,
  faBuilding as faBuildingLight,
  faBuildings as faBuildingsLight,
  faCaretDown as faCaretDownLight,
  faCaretRight as faCaretRightLight,
  faChevronDown as faChevronDownLight,
  faCheckCircle as faCheckCircleLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faChevronUp as faChevronUpLight,
  faClipboard as faClipboardLight,
  faCloudDownload as faCloudDownloadLight,
  faCopy as faCopyLight,
  faDollarSign as faDollarSignLight,
  faDoNotEnter as faDoNotEnterLight,
  faEdit as faEditLight,
  faEllipsisV as faEllipsisVLight,
  faExchange as faExchangeLight,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faFileCertificate as faFileCertificateLight,
  faFilter as faFilterLight,
  faFolder as faFolderLight,
  faFolderTree as faFolderTreeLight,
  faHome as faHomeLight,
  faInfoCircle as faInfoCircleLight,
  faKey as faKeyLight,
  faList as faListLight,
  faMinus as faMinusLight,
  faMinusCircle as faMinusCircleLight,
  faPaperPlane as faPaperPlaneLight,
  faPlusCircle as faPlusCircleLight,
  faQuestionCircle as faQuestionCircleLight,
  faRouter as faRouterLight,
  faSave as faSaveLight,
  faSearch as faSearchLight,
  faSignOut as faSignOutLight,
  faSitemap as faSitemapLight,
  faSpinner as faSpinnerLight,
  faSync as faSyncLight,
  faThermometerHalf as faThermometerHalfLight,
  faTimes as faTimesLight,
  faTrash as faTrashLight,
  faUndo as faUndoLight,
  faUpload as faUploadLight,
  faUsers as faUsersLight,
  faUserCrown as faUserCrownLight,
  faUserFriends as faUserFriendsLight,
  faUserLock as faUserLockLight,
} from '@fortawesome/pro-light-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';

export default function initIcons() {
  library.add(
    faArrowToLeftLight,
    faAws,
    faBarsLight,
    faBellOnLight,
    faBoltLight,
    faBroadcastTowerLight,
    faBuildingLight,
    faBuildingsLight,
    faCaretDownLight,
    faCaretRightLight,
    faCheckCircleLight,
    faChevronDownLight,
    faChevronLeftLight,
    faChevronRightLight,
    faChevronUpLight,
    faClipboardLight,
    faCloudDownloadLight,
    faCopyLight,
    faDollarSignLight,
    faDoNotEnterLight,
    faEditLight,
    faEllipsisVLight,
    faExchangeLight,
    faEyeLight,
    faEyeSlashLight,
    faFileCertificateLight,
    faFilterLight,
    faFolderLight,
    faFolderTreeLight,
    faHomeLight,
    faInfoCircleLight,
    faKeyLight,
    faListLight,
    faMinusLight,
    faMinusCircleLight,
    faPaperPlaneLight,
    faPlusCircleLight,
    faQuestionCircleLight,
    faRouterLight,
    faSaveLight,
    faSearchLight,
    faSignOutLight,
    faSitemapLight,
    faThermometerHalfLight,
    faSpinnerLight,
    faSyncLight,
    faTimesLight,
    faTrashLight,
    faUndoLight,
    faUploadLight,
    faUsersLight,
    faUserCrownLight,
    faUserFriendsLight,
    faUserLockLight
  );
}
