import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ROUTES } from '../../../helpers/constants';
import generateNodes from '../../../helpers/solarTree';
import { SOLAR_APP_ID } from '../../../store/solar';
import useTab from '../../../store/hooks/useTab';
import TabBar from '../../../components/Tab/TabBar';
import LicensesGrid from '../../../components/LicensesGrid';
import KioskUsage from '../../../components/KioskUsage';
import MembershipsGrid from '../../../components/MembershipsGrid';
import UsersGrid from '../../../components/UsersGrid';
import ResourcesPage from '../ResourcesPage';
import KPIs from './KPIs';
import Backfill from './Backfill';

const RESOURCES = 'resources';
const LICENSES = 'licenses';
const USERS = 'users';
const MEMBERSHIPS = 'memberships';
const KPIS = 'kpis';
const BACKFILL = 'backfill';
const KIOSK_USAGE = 'kiosk-usage';
const TABS = [RESOURCES, LICENSES, USERS, MEMBERSHIPS, KPIS];

export default function LightLevel() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const solarState = useSelector((state) => state.solar);
  const [nodes, setNodes] = useState([]);
  const [tabs, setTabs] = useState(TABS);
  const selectedTab = useTab(tabs);

  useEffect(() => {
    if (isMobile) {
      setTabs(TABS);
    } else {
      setTabs([...TABS, BACKFILL, KIOSK_USAGE]);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!solarState.loading) {
      setNodes(generateNodes(solarState));
    }
  }, [solarState]);

  return (
    <TabBar tabs={tabs} selectedTab={selectedTab}>
      <ResourcesPage
        tab={RESOURCES}
        appId={SOLAR_APP_ID}
        nodes={nodes}
        path={ROUTES.LIGHTLEVEL.path}
      />
      <LicensesGrid tab={LICENSES} appId={SOLAR_APP_ID} />
      <UsersGrid tab={USERS} appId={SOLAR_APP_ID} />
      <MembershipsGrid tab={MEMBERSHIPS} appId={SOLAR_APP_ID} />
      <KPIs tab={KPIS} />
      <Backfill tab={BACKFILL} />
      <KioskUsage tab={KIOSK_USAGE} appId={SOLAR_APP_ID} />
    </TabBar>
  );
}
