import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  EditingState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';

import { openConfirmDialog } from '../../../../store/dialogs';
import {
  generateMeterPeakPerformance,
  postPeak,
  putPeak,
} from '../../../../store/demand';
import BaseTable from '../../../../components/Table/BaseTable';
import { DisplaykWTypeProvider } from '../../../../components/Table/providers';
import SelectYear from './SelectYear';

export default function CoincidencePeaks() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { peaks } = useSelector((state) => state.demand);

  const [open, setOpen] = useState(false);
  const [newYear, setNewYear] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  const [rows, setRows] = useState([]);
  const [columns] = useState([
    { title: 'Date', name: 'date', width: 0.27 },
    { title: 'Hour', name: 'hour', width: 0.27 },
    { title: 'Coincidence Peak', name: 'value', width: 0.27 },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'value', align: 'right' },
  ]);

  useEffect(() => {
    let _years = peaks.map((peak) => peak.year);
    setYears(_years.sort((a, b) => a - b));
  }, [peaks]);

  useEffect(() => {
    if (selectedYear === '') {
      setRows([]);
      setCanSubmit(false);
      return;
    }
    const yearPeaks = peaks.find((peak) => peak.year === selectedYear);
    if (yearPeaks) {
      setRows(yearPeaks?.peaks);
    }
    setCanSubmit(false);
  }, [peaks, selectedYear]);

  const addYearPeaks = (year) => {
    if (isNaN(year)) {
      toast.error('Year is not a number');
      return;
    }

    if (years.includes(year)) {
      toast.error('Year already exists');
      return;
    }
    if (year < 2016) {
      toast.error('Year must be after 2015');
      return;
    }

    let emptyRows = Array(5).fill({
      date: `${year}-XX-XX`,
      hour: '',
      value: '',
    });
    emptyRows = emptyRows.map((item, idx) => ({ ...item, id: idx }));
    setRows(emptyRows);
    setOpen(false);
    setYears([...years, year]);
    setSelectedYear(year);
  };

  const handleRevert = () => {
    const yearPeaks = peaks.find((peak) => peak.year === selectedYear);
    setRows(yearPeaks?.peaks || []);
    setCanSubmit(false);
  };

  const handleSubmit = () => {
    let errors = [];
    let yearPeaks = peaks.find((peak) => peak.year === String(selectedYear));
    let updatedPeakItem = {};
    if (yearPeaks) {
      updatedPeakItem = cloneDeep(yearPeaks);
    } else {
      updatedPeakItem = { year: selectedYear, peaks: [], rto_id: 'PJM' };
    }

    // Create new array with validated peaks
    updatedPeakItem.peaks = rows.map((peak, idx) => {
      const validatedPeak = { ...peak };

      // check if date is in the correct format YYYY-MM-DD
      if (!/^\d{4}-\d{2}-\d{2}$/.test(validatedPeak.date)) {
        errors.push(`Peak ${idx + 1}: Date must be in the format YYYY-MM-DD`);
      }

      // validate hour
      const hourNum = parseInt(validatedPeak.hour);
      if (isNaN(hourNum) || hourNum < 0 || hourNum > 23) {
        errors.push(`Peak ${idx + 1}: Hour must be between 0 and 23`);
      }
      validatedPeak.hour = hourNum;

      // validate value
      const valueNum = parseFloat(validatedPeak.value);
      if (isNaN(valueNum)) {
        errors.push(`Peak ${idx + 1}: Value must be a number`);
      }
      validatedPeak.value = valueNum;

      return validatedPeak;
    });

    if (errors.length > 0) {
      errors.forEach((error) => {
        toast.error(error);
      });
      return;
    }

    if (peaks.find((peak) => peak.year === selectedYear)) {
      dispatch(putPeak(updatedPeakItem));
    } else {
      dispatch(postPeak(updatedPeakItem));
    }
    setCanSubmit(false);
  };

  const commitChanges = ({ changed }) => {
    let changedRows;
    if (changed) {
      changedRows = rows.map((row, idx) => {
        if (changed[idx]) {
          return { ...row, ...changed[idx] };
        } else {
          return row;
        }
      });
    }
    setRows(changedRows);
    setCanSubmit(true);
  };

  const handleChangeNewYear = (e) => {
    setNewYear(e.target.value);
  };

  const handleGenerateMeterPeakPerformance = () => {
    dispatch(
      openConfirmDialog({
        title: 'Generate Meter Peak Performance',
        message: (
          <>
            <p>
              Are you sure you want to generate peak performance data for each
              meter?
            </p>
            <p>
              This will run a job for each meter in the PJM RTO and generate a
              peak performance analysis for each Coincidence Peak in{' '}
              {selectedYear}.
            </p>
            <p>
              These jobs may take a while to complete and the data will be
              available in each Organization's Performance tab after the jobs
              finish.
            </p>
          </>
        ),
        onConfirm: () => {
          dispatch(generateMeterPeakPerformance(selectedYear));
        },
      })
    );
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: 800, width: '100%', px: 1, mt: 1, pb: 5 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}>
        <Typography
          variant='h6'
          sx={{ color: (theme) => theme.palette.primary.main }}
          fontWeight='bold'>
          PJM Coincidence Peaks
        </Typography>
        <Box
          sx={{
            pt: 1,
            px: 1,
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
          }}>
          <SelectYear
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
          <Divider orientation='vertical' flexItem />
          <Tooltip title='Revert' placement='top'>
            <IconButton onClick={handleRevert} sx={{ height: 35, width: 35 }}>
              <FontAwesomeIcon icon={['fal', 'undo']} size='sm' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Save' placement='top'>
            <IconButton
              disabled={!canSubmit}
              onClick={handleSubmit}
              sx={{ height: 35, width: 35 }}>
              <FontAwesomeIcon icon={['fal', 'save']} size='sm' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Generate Meter Peak Performance' placement='top'>
            <IconButton
              disabled={canSubmit}
              onClick={handleGenerateMeterPeakPerformance}
              sx={{ height: 35, width: 35 }}>
              <FontAwesomeIcon icon={['fal', 'paper-plane']} size='sm' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Add Year Peaks' placement='top'>
            <IconButton
              onClick={() => setOpen(true)}
              sx={{ height: 35, width: 35 }}>
              <FontAwesomeIcon icon={['fal', 'plus-circle']} size='sm' />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: 'center',
        }}>
        <Card raised sx={{ maxWidth: '96vw', width: '100%', px: 1, pb: 0.5 }}>
          <BaseTable
            minWidth={500}
            rows={rows}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            headerRow={true}
            inlineEditing={true}>
            <DisplaykWTypeProvider for={['value']} />

            <EditingState
              onCommitChanges={commitChanges}
              columnExtensions={[]}
            />
            <FilteringState />
            <SortingState defaultSorting={[]} />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering columnExtensions={[]} />
            <IntegratedSorting columnExtensions={[]} />
            <IntegratedPaging />
          </BaseTable>
        </Card>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Year Peaks</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label='Year'
            variant='standard'
            value={newYear}
            onChange={handleChangeNewYear}
          />
          <Button onClick={() => addYearPeaks(newYear)} variant='contained'>
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
