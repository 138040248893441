import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import { ROUTES } from '../../helpers/constants';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { toastr } from '../../components/CustomToast';

const authenticateDemandUser = createAsyncThunk(
  'demand/authenticateDemandUser',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(DEMAND_APP_ID).GET(
        '/resource/admin/init_auth'
      );
      return { user };
    } catch (err) {
      console.warn('Demand Authentication Error');
      throw new Error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getDemandResources = createAsyncThunk(
  'demand/getDemandResources',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());

      const webClient = new WebAPIClient(DEMAND_APP_ID);

      const responses = await Promise.all([
        webClient.GET('/resource/admin/alarms'),
        webClient.GET('/resource/admin/memberships'),
        webClient.GET('/resource/admin/niagaras'),
        webClient.GET('/resource/admin/organizations'),
        webClient.GET('/resource/admin/portfolio_memberships'),
        webClient.GET('/resource/admin/sites'),
        webClient.GET('/resource/admin/licenses'),
        webClient.GET('/resource/admin/meters'),
        webClient.GET('/resource/admin/users'),
        webClient.GET('/resource/admin/rtos'),
        webClient.GET('/resource/peaks/PJM'),
      ]);
      const alarms = responses[0];
      const memberships = responses[1];
      const niagaras = responses[2];
      const organizations = responses[3];
      const portfolioMemberships = responses[4];
      const sites = responses[5];
      const licenses = responses[6];
      const meters = responses[7];
      const users = responses[8];
      const rtos = responses[9];
      const peaks = responses[10];
      return {
        alarms,
        memberships,
        niagaras,
        organizations,
        portfolioMemberships,
        sites,
        licenses,
        meters,
        users,
        rtos,
        peaks,
      };
    } catch (err) {
      errorResponseToastr(err);
      dispatch(push(ROUTES.LANDING.path));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putRTO = createAsyncThunk(
  'demand/putRTO',
  async (rto, { dispatch, getState }) => {
    const rtos = getState().demand.rtos;
    let _rtos = cloneDeep(rtos);

    try {
      dispatch(showLoading());
      let _rto = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/rtos/${rto.rto_id}`,
        rto
      );

      remove(_rtos, { rto_id: get(rto, 'rto_id') });
      _rtos = concat(_rtos, _rto);

      toastr.success({
        title: 'RTO updated',
        message: _rto.name,
      });

      return { rtos: _rtos };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putNiagara = createAsyncThunk(
  'demand/putNiagara',
  async ({ active, devices, niagara_id, org_id }, { dispatch, getState }) => {
    const niagaras = getState().demand.niagaras;
    let _niagaras = cloneDeep(niagaras);

    try {
      dispatch(showLoading());
      let updatedNiagara = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/niagaras/${niagara_id}`,
        {
          active,
          devices,
          niagara_id,
          org_id,
        }
      );

      remove(_niagaras, { niagara_id });
      _niagaras = concat(_niagaras, updatedNiagara);

      toastr.success({
        title: 'Niagara updated',
        message: updatedNiagara.name,
      });
      return { niagaras: _niagaras };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putPeak = createAsyncThunk(
  'demand/putPeak',
  async (peak, { dispatch, getState }) => {
    const peaks = getState().demand.peaks;
    let _peaks = cloneDeep(peaks);
    try {
      dispatch(showLoading());
      let updatedPeak = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/peaks/${peak.year}`,
        peak
      );
      remove(_peaks, { year: peak.year });
      _peaks = concat(_peaks, updatedPeak);
      toastr.success({
        title: 'Peaks updated',
        message: updatedPeak.year,
      });
      return { peaks: _peaks };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postPeak = createAsyncThunk(
  'demand/postPeak',
  async (peak, { dispatch, getState }) => {
    const peaks = getState().demand.peaks;
    let _peaks = cloneDeep(peaks);
    try {
      dispatch(showLoading());
      let newPeak = await new WebAPIClient(DEMAND_APP_ID).POST(
        '/resource/peaks',
        peak
      );
      _peaks = concat(_peaks, newPeak);
      toastr.success({
        title: 'Peaks created',
        message: newPeak.year,
      });
      return { peaks: _peaks };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const generateMeterPeakPerformance = createAsyncThunk(
  'demand/generateMeterPeakPerformance',
  async (year, { dispatch, getState }) => {
    try {
      dispatch(showLoading());
      await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/performance_peaks/${year}`
      );
      toastr.success({
        title: 'Jobs started',
        message: `Meter Peak Performance for ${year}`,
      });
      return {};
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  authenticateDemandUser,
  getDemandResources,
  putRTO,
  putNiagara,
  putPeak,
  postPeak,
  generateMeterPeakPerformance,
};
