import React from 'react';

import useTab from '../../../store/hooks/useTab';
import TabBar from '../../../components/Tab/TabBar';
import KPIs from './KPIs';
import Licenses from './Licenses';
import OrganizationUsers from './OrganizationUsers';
import PortfolioUsers from './PortfolioUsers';
import PortfolioOrganizations from './PortfolioOrganizations';
import Tools from './Tools';

const KPIS_TAB = 'kpis';
const LICENSES_TAB = 'licenses';
const ORGANIZATION_USERS_TAB = 'organization_users';
const PORTFOLIO_USERS_TAB = 'portfolio_users';
const PORTFOLIO_ORGANIZATIONS_TAB = 'portfolio_organizations';
const TOOLS_TAB = 'tools';
const TABS = [
  KPIS_TAB,
  LICENSES_TAB,
  ORGANIZATION_USERS_TAB,
  PORTFOLIO_USERS_TAB,
  PORTFOLIO_ORGANIZATIONS_TAB,
  TOOLS_TAB,
];

export default function EnergyTracer() {
  const selectedTab = useTab(TABS);
  return (
    <TabBar tabs={TABS} selectedTab={selectedTab}>
      <KPIs tab={KPIS_TAB} />
      <Licenses tab={LICENSES_TAB} />
      <OrganizationUsers tab={ORGANIZATION_USERS_TAB} />
      <PortfolioUsers tab={PORTFOLIO_USERS_TAB} />
      <PortfolioOrganizations tab={PORTFOLIO_ORGANIZATIONS_TAB} />
      <Tools tab={TOOLS_TAB} />
    </TabBar>
  );
}
