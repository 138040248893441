import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'app',
  initialState: { drawerExpanded: false, loading: 0 },
  reducers: {
    setDrawerExpanded(state, action) {
      state.drawerExpanded = action.payload;
    },
    showLoading: (state) => {
      state.loading += 1;
    },
    hideLoading: (state) => {
      if (state.loading > 0) {
        state.loading -= 1;
      }
    },
  },
});

const { setDrawerExpanded, showLoading, hideLoading } = actions;
export { setDrawerExpanded, showLoading, hideLoading };
export default reducer;
