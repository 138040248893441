import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import reduce from 'lodash/reduce';

import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';

import { getMetricNames } from './metrics';
import GroupContainer from './GroupContainer';
import KPITrendChart from './KPITrendChart';
import WidgetLoader from '../Loaders/WidgetLoader';
import { StyledSubtab, StyledSubtabs } from '../StyledSubtabs';

export default function KPIsPage(props) {
  const { metrics, fetchData } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(Object.keys(metrics)[0]);
  const [metricCounts, setMetricCounts] = useState(null);

  const fetchChartData = async () => {
    setLoading(true);
    const metricNames = getMetricNames(metrics);
    const start = dayjs().subtract(1, 'days').startOf('day').valueOf();
    const end = dayjs().valueOf();
    const payload = await fetchData(start, end, metricNames);
    const timestamps = Object.keys(payload);
    const mostRecent = Math.max(timestamps);

    let mostRecentData = payload[mostRecent];
    mostRecentData = reduce(
      mostRecentData,
      (acc, { metric, value }) => {
        return { ...acc, [metric]: value };
      },
      {}
    );
    setMetricCounts(mostRecentData);
    setLoading(false);
  };

  useEffect(() => {
    if (metricCounts === null) {
      fetchChartData();
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (e, groupName) => {
    setSelectedGroup(groupName);
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      sx={{ maxWidth: '100%', px: 1, mt: 1 }}>
      {isMobile ? (
        <>
          <Grid size={{ xs: 12 }}>
            <StyledSubtabs
              variant='fullWidth'
              sx={{ pb: 1 }}
              value={selectedGroup}
              onChange={handleSelect}>
              {Object.keys(metrics).map((metric) => (
                <StyledSubtab key={metric} label={metric} value={metric} />
              ))}
            </StyledSubtabs>
          </Grid>
          <Grid
            size={{ xs: 12 }}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              raised
              sx={{
                p: 1,
                maxWidth: 600,
                width: '100%',
              }}>
              <WidgetLoader loading={loading} />
              <GroupContainer
                metrics={metrics[selectedGroup]}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            size={{ xs: 4 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}>
            <Card
              raised
              sx={{
                p: 1,
                mb: 1,
                width: '100%',
              }}>
              <GroupContainer
                metrics={metrics.alarms}
                metricCounts={metricCounts}
              />
            </Card>
            <Card
              raised
              sx={{
                p: 1,
                width: '100%',
              }}>
              <GroupContainer
                metrics={metrics.users}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
          <Grid
            size={{ xs: 4 }}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card raised sx={{ p: 1, width: '100%' }}>
              <WidgetLoader loading={loading} />
              <GroupContainer
                metrics={metrics.resources}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
        </>
      )}
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <KPITrendChart allMetrics={metrics} fetchData={fetchData} />
      </Grid>
    </Grid>
  );
}
