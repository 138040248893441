import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function LoadingBar() {
  const loading = useSelector((state) => state.app.loading);
  return (
    loading > 0 && (
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          height: 5,
          zIndex: 1500,
        }}>
        <LinearProgress variant='indeterminate' />
      </Box>
    )
  );
}

export default LoadingBar;
