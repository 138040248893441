import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Typography from '@mui/material/Typography';
import { displaykW } from '../../../helpers/display-energy';

export const DisplaykWTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        return (
          <Typography align='right'>
            {value !== null ? displaykW(value) : '-'}
          </Typography>
        );
      }}
      {...props}
    />
  );
};
