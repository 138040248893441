import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '../../../helpers/constants';
import generateNodes from '../../../helpers/demandTree';
import { DEMAND_APP_ID } from '../../../store/demand';
import useTab from '../../../store/hooks/useTab';
import TabBar from '../../../components/Tab/TabBar';
import LicensesGrid from '../../../components/LicensesGrid';
import MembershipsGrid from '../../../components/MembershipsGrid';
import UsersGrid from '../../../components/UsersGrid';
import KioskUsage from '../../../components/KioskUsage';
import ResourcesPage from '../ResourcesPage';
import KPIs from './KPIs';
import PJMMarkets from './PJMMarkets';
import LoadShed from './LoadShed';
import CoincidencePeaks from './CoincidencePeaks';

const RESOURCES = 'resources';
const LICENSES = 'licenses';
const USERS = 'users';
const MEMBERSHIPS = 'memberships';
const LOAD_SHED = 'load-shed';
const COINCIDENCE_PEAKS = 'coincidence-peaks';
const KPIS = 'kpis';
const MARKETS = 'markets';
const KIOSK_USAGE = 'kiosk-usage';
const TABS = [
  RESOURCES,
  LICENSES,
  USERS,
  MEMBERSHIPS,
  LOAD_SHED,
  COINCIDENCE_PEAKS,
  KPIS,
  MARKETS,
  KIOSK_USAGE,
];

export default function Gridcap() {
  const selectedTab = useTab(TABS);
  const demandState = useSelector((state) => state.demand);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    if (!demandState.loading) {
      setNodes(generateNodes(demandState));
    }
  }, [demandState]);

  return (
    <TabBar tabs={TABS} selectedTab={selectedTab}>
      <ResourcesPage
        tab={RESOURCES}
        appId={DEMAND_APP_ID}
        nodes={nodes}
        path={ROUTES.GRIDCAP.path}
      />
      <LicensesGrid tab={LICENSES} appId={DEMAND_APP_ID} />
      <UsersGrid tab={USERS} appId={DEMAND_APP_ID} />
      <MembershipsGrid tab={MEMBERSHIPS} appId={DEMAND_APP_ID} />
      <LoadShed tab={LOAD_SHED} />
      <CoincidencePeaks tab={COINCIDENCE_PEAKS} />
      <KPIs tab={KPIS} />
      <PJMMarkets tab={MARKETS} />
      <KioskUsage tab={KIOSK_USAGE} appId={DEMAND_APP_ID} />
    </TabBar>
  );
}
