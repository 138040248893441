import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { demand as initialState } from '../initialState';

import {
  authenticateDemandUser,
  getDemandResources,
  putRTO,
  putNiagara,
  putPeak,
  postPeak,
  generateMeterPeakPerformance,
} from './_demand';
import {
  postDemandOrganization,
  putDemandLicense,
  putDemandOrganization,
} from './organizations';
import {
  postDemandPortfolioMemberships,
  deleteDemandPortfolioMembership,
} from './portfolioMemberships';
import {
  deleteDemandMembership,
  postDemandMembership,
  putDemandMembership,
} from './membership';
import {
  deleteDemandMeter,
  postDemandMeter,
  putDemandMeter,
  putMeterShedValues,
} from './meters';
import { deleteDemandSite, postDemandSite, putDemandSite } from './sites';
import {
  deleteDemandUser,
  postDemandUser,
  putDemandUser,
  generateDemandAPIKey,
} from './user';

export const DEMAND_APP_ID = 'demand';

const { reducer } = createSlice({
  name: DEMAND_APP_ID,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      authenticateDemandUser,
      getDemandResources,
      postDemandOrganization,
      putDemandOrganization,
      postDemandPortfolioMemberships,
      deleteDemandPortfolioMembership,
      putDemandLicense,
      postDemandMembership,
      putDemandMembership,
      deleteDemandMembership,
      deleteDemandMeter,
      postDemandMeter,
      putDemandMeter,
      putMeterShedValues,
      deleteDemandSite,
      postDemandSite,
      putDemandSite,
      deleteDemandUser,
      postDemandUser,
      putDemandUser,
      generateDemandAPIKey,
      putRTO,
      putNiagara,
      putPeak,
      postPeak,
    ]);
  },
});

// Export the reducer, either as a default or named export
export {
  authenticateDemandUser,
  getDemandResources,
  postDemandOrganization,
  putDemandOrganization,
  postDemandPortfolioMemberships,
  deleteDemandPortfolioMembership,
  putDemandLicense,
  postDemandMembership,
  putDemandMembership,
  deleteDemandMembership,
  deleteDemandMeter,
  postDemandMeter,
  putDemandMeter,
  putMeterShedValues,
  deleteDemandSite,
  postDemandSite,
  putDemandSite,
  deleteDemandUser,
  postDemandUser,
  putDemandUser,
  generateDemandAPIKey,
  putRTO,
  putNiagara,
  putPeak,
  postPeak,
  generateMeterPeakPerformance,
};
export default reducer;
